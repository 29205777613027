<template>
  <div>
    <b-card title="Kullanıcı Anketleri">
      <div style="min-height: 300px">
        <b-table
          responsive
          selectable
          select-mode="single"
          hover
          class="mt-2 odd-even-table"
          :per-page="resultsPerPage"
          :current-page="resultsCurrentPage"
          :items="surveyList"
          :fields="surveyListTableFields"
          @row-selected="showSurveyDetails"
        >
          <template #cell(index)="data">
            {{ (resultsCurrentPage - 1) * resultsPerPage + data.index + 1 }}
          </template>
          <template #cell(city)="data">
            <p style="margin-bottom: 0px">
              {{ data.item.city }}
            </p>
          </template>

          <template #cell(survey_name)="data">
            <p style="margin-bottom: 0px; min-width: 80px">
              {{ data.item.survey_name }}
            </p>
          </template>

          <template #cell(date_time)="data">
            <p style="margin-bottom: 0px; min-width: 100px">
              {{ dateTimeToDayText(data.item.date_time) }}
            </p>
          </template>

          <template #cell(question_text)="data">
            <p
              style="
                margin-bottom: 0px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              {{ data.item.question_text }}
            </p>
          </template>
        </b-table>

        <div style="display: flex; margin: 20px 10px 20px 10px">
          <div style="flex-grow: 1"></div>
          <b-pagination
            v-if="surveyList.length > resultsPerPage"
            v-model="resultsCurrentPage"
            :total-rows="surveyList.length"
            :per-page="resultsPerPage"
          />
        </div>
      </div>
    </b-card>

    <b-card v-if="selectedSurvey" style="margin: 0px auto">
      <div style="display: flex; margin-bottom: 20px">
        <h4 style="flex-grow: 1">{{ selectedSurvey.survey_name }}</h4>
        <h6>{{ selectedSurvey.user_answer_count }} kişi yanıtladı</h6>
      </div>
      <div style="min-height: 450px">
        <p style="margin: 15px; font-weight: 500">
          {{ selectedSurvey.question_text }}
        </p>
        <div v-for="index in 10" :key="index">
          <div
            v-if="selectedSurvey['option_' + index]"
            style="display: flex; margin: 20px 10px 0px 10px"
          >
            <div
              style="
                border: 1px solid #777;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 15px;
                margin-top: 8px;
              "
            ></div>
            <div style="flex-grow: 1">
              <p style="font-size: 1.1rem; margin-bottom: 0px">
                {{ selectedSurvey["option_" + index] }}
              </p>
              <div style="display: flex">
                <b-progress
                  style="flex-grow: 1; margin-top: 6px; height: 4px !important"
                  v-model="getOptionSelectionPercentage(index).value"
                  class="progress-bar-primary"
                  max="100"
                >
                </b-progress>
                <div style="min-width: 110px; text-align: left">
                  <p style="margin: 0 0 0 10px">
                    <span style="font-weight: 600; color: #46b4ab">
                      %{{ getOptionSelectionPercentage(index).value }}
                    </span>

                    <span style="font-size: 0.95rem">
                      ({{
                        selectedSurvey["result_" + index]
                          ? selectedSurvey["result_" + index]
                          : 0
                      }}
                      kişi)
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  BTabs,
  BTab,
  BFormTextarea,
  BProgress,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BTabs,
    BTab,
    BFormTextarea,
    BProgress,
  },
  data() {
    return {
      surveyList: [],
      surveyListTableFields: [
        { key: "index", label: "" },
        { key: "survey_name", label: "Anket Adı" },
        { key: "date_time", label: "Anket Tarihi" },
        { key: "question_text", label: "Soru" },
        { key: "user_answer_count", label: "Cevaplayan Sayısı" },
      ],
      resultsPerPage: 10,
      resultsCurrentPage: 1,
      selectedSurvey: null,
    };
  },
  computed: {},
  methods: {
    async showSurveyDetails(surveys) {
      this.selectedSurvey = null;
      if (surveys.length > 0) {
        this.selectedSurvey = surveys[0];
      }
    },

    getOptionSelectionPercentage(index) {
      if (index <= 0 || index > 10) {
        return -1;
      }
      var selectionCount = this.selectedSurvey["result_" + index];
      var totalUserCount = this.selectedSurvey.user_answer_count;
      if (selectionCount && totalUserCount) {
        return { value: Math.round((100 * selectionCount) / totalUserCount) };
      }
      return { value: 0 };
    },

    dateTimeToDayText(dateTime) {
      if (dateTime && dateTime.trim().length == 19) {
        dateTime = dateTime.trim();
        var date = dateTime.split(" ")[0];
        var year = date.split("-")[0];
        var month = date.split("-")[1];
        var day = date.split("-")[2];
        if (dateTime.slice(0, 10) == new Date().toISOString().slice(0, 10)) {
          return "Bugün";
        }
        switch (month) {
          case "01":
            return day + " Ocak " + year;
          case "02":
            return day + " Şubat " + year;
          case "03":
            return day + " Mart " + year;
          case "04":
            return day + " Nisan " + year;
          case "05":
            return day + " Mayıs " + year;
          case "06":
            return day + " Haziran " + year;
          case "07":
            return day + " Temmuz " + year;
          case "08":
            return day + " Ağustos " + year;
          case "09":
            return day + " Eylül " + year;
          case "10":
            return day + " Ekim " + year;
          case "11":
            return day + " Kasım " + year;
          case "12":
            return day + " Aralık " + year;
        }
      }
      return "-";
    },

    toTitleCase(str) {
      return !str
        ? ""
        : str
            .replace("(", "( ")
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            )
            .replace("( ", "(");
    },
  },
  async created() {
    let moduleId = "user_surveys";
    let moduleName = "Çoktan Seçmeli Anketler"; 
    if(JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1){
      this.$router.push("/erisim-kapali?moduleName="+moduleName);
      return; 
    } 

    var surveys = await store.dispatch("adminUserSurveys/getSurveys");
    if (surveys && surveys.length > 0) {
      this.surveyList = surveys;
      this.selectedSurvey = surveys[0];
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
